
































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { Role } from "@/models/entities/role.interface";

@Component
export default class UserPermissions extends Vue {
  @Prop({ required: true, default: null, type: Object })
  private roles: { [id: string]: Role[] };
  @PropSync("roleIds", {
    required: true,
    default: null,
    type: Array,
  })
  private _roleIds: number[];

  private expanded: string = null;

  private get keys(): string[] {
    return this.roles ? Object.keys(this.roles) : [];
  }

  private getSelectedPerKey(key: string): string {
    let arr = this.roles[key];
    let counter = 0;
    if (this._roleIds.length) {
      counter = arr.filter(
        (el) => this._roleIds.indexOf(el.roleId) > -1
      ).length;
    }
    return `${counter}/${arr.length}`;
  }

  private onCheckboxClick(roleId: number): void {
    const idx = this._roleIds.indexOf(roleId);
    idx === 1 ? this._roleIds.push(roleId) : this._roleIds.splice(idx, 1);
  }
}
